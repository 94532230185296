$primary: var(--primary, #19b394);
$foreground-light: var(--foreground-light, #909090);
$border: var(--border, #d6d6d6);
$background-dim: var(--background-dim, #f3f3f3);
$base-unit: var(--base-unit, 8px);
$foreground-disabled: var(--foreground-disabled, rgba(#161616, 0.16));
$background: var(--background, #fff);

@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

.sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: calcSize(2);
  height: calcSize(6);
  overflow: auto;
}
.sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  padding: 11px calcSize(2);
  line-height: calcSize(3);
  border: 1px solid $border;
  outline: none;
  font-size: calcSize(2);
  font-weight: 400;
  background: $background;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:not(:first-of-type) {
    margin-left: -1px;
  }
}

.sv-button-group__item:hover {
  background-color: $background-dim;
}

.sv-button-group__item-icon {
  display: block;
  height: calcSize(3);
  use {
    fill: $foreground-light;
  }
}

.sv-button-group__item--selected {
  font-weight: 600;
  color: $primary;
  .sv-button-group__item-icon use {
    fill: $primary;
  }
  &:hover {
    background-color: $background;
  }
}
.sv-button-group__item-decorator {
  display: flex;
  align-items: center;
}
.sv-button-group__item-icon +
.sv-button-group__item-caption {
  margin-left: calcSize(1);
}
.sv-button-group__item--disabled {
  color: $foreground-disabled;
  cursor: default;
  .sv-button-group__item-icon use {
    fill: $foreground-disabled;
  }
  &:hover {
    background-color: $background;
  }
}
