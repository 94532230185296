.sv-question .sv-selectbase {
  margin-bottom: 4px;
}
.sv-selectbase__item {
  margin-bottom: 0.425em;
  vertical-align: top;
}
.sv-selectbase__item--inline {
  display: inline-block;
  padding-right: 5%;
}
.sv-selectbase__column {
  min-width: 140px;
  vertical-align: top;
}
.sv-selectbase__label {
  display: block;
  box-sizing: border-box;
  width: 100%;
  cursor: inherit;
}
.sv-selectbase__clear-btn {
  margin-top: 0.9em;
  background-color: $clean-button-color;
}
.sv-selectbase .sv-selectbase__item.sv-q-col-1 {
  padding-right: 0;
}
