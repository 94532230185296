.sv-header {
}

.sv-header {
  white-space: nowrap;
}

.sv-logo {
}
.sv-logo--left {
  display: inline-block;
  vertical-align: top;
  margin-right: 2em;
}
.sv-logo--right {
  vertical-align: top;
  margin-left: 2em;
  float: right;
}
.sv-logo--right + .sv-logo--right-tail {
  clear: both;
}
.sv-logo--top {
  display: block;
  width: 100%;
  text-align: center;
}
.sv-logo--bottom {
  display: block;
  width: 100%;
  text-align: center;
}

.sv-header__text {
  display: inline-block;
  vertical-align: top;
}
