.sv-imagepicker__item {
  border: none;
  padding: 0.24em;
}
.sv-imagepicker__item--inline {
  display: inline-block;
}
.sv-imagepicker__item--inline:not(:last-child) {
  margin-right: 4%;
}
.sv-imagepicker__image {
  border: 0.24em solid transparent;
  display: block;
  pointer-events: none;
}
.sv-imagepicker__label {
  cursor: inherit;
}
.sv-imagepicker__text {
  font-size: 1.14em;
  padding-left: 0.24em;
}
.sv-imagepicker__item--allowhover:hover {
  .sv-imagepicker__image {
    background-color: $main-hover-color;
    border-color: $main-hover-color;
  }
}
.sv-imagepicker__item--checked {
  .sv-imagepicker__image {
    background-color: $main-color;
    border-color: $main-color;
  }
}
.sv-imagepicker__item {
  cursor: pointer;
}
.sv-imagepicker__item--disabled {
  cursor: default;
}
.sv-imagepicker__item--disabled.sv-imagepicker__item--checked {
  .sv-imagepicker__image {
    background-color: $disable-color;
    border-color: $disable-color;
  }
}
